<template>
  <div>
    <b-button
        type="button"
        variant="info"
        class="ml-3"
        @click="showRepaymentSchedules()"
    >Эргэн төлөлтийн хуваарь харах</b-button
    >

    <b-modal ref="my-modal" title="" size="xl">
      <template v-slot:modal-header>
        <h4 class="mb-0">Зээлийн хуваарь </h4>
        <b-button
            variant="primary"
            class="float-right"
            size="sm"
            @click="print()"
        >
          Хэвлэх
        </b-button>
      </template>
      <div class="page-container" id="printContainer">
        <div class="page-header" style="text-align: right">
          Барьцаат зээлийн гэрээ
        </div>
        <div class="page-footer">
          <div class="pull-right mr-4">Зээлдэгч: ___________________</div>
          <div class=" ml-4">ББСБ-ыг төлөөлж:________________________</div>
        </div>
        <table>
          <thead>
          <tr>
            <td>
              <!--place holder for the fixed-position header-->
              <div class="page-header-space"></div>
            </td>
          </tr>
          </thead>

          <tbody>
          <tr v-if="loanGroup=='P3001'">
            <td>
              <div class="page">
                <div class="clearfix">
                    <span class="pull-right contract_number"
                    >ЗГ-{{ contract_number }} тоот гэрээний Хавсралт №1</span
                    >
                </div>
                <div class="h4 text-center mt-3 mb-5">
                  ТҮРЭЭСИЙН ЭРГЭН ТӨЛӨЛТИЙН ХУВААРЬ
                </div>
                <ol>
                  <li>
                    <p v-if="selectCustomer">
                      Түрээслэгч {{ selectCustomer.lastname }} овогтой
                      {{ selectCustomer.firstname }} /{{
                        selectCustomer.registerNumber
                      }}/ нь “Нөмөр кредит Лизинг” ХХК-аас авсан
                      {{ amount | number }} төгрөгийн түрээс, түүний хүүг доорх
                      хуваарийн дагуу төлж барагдуулна.Үүнд:
                    </p>
                    <div class="text-center">
                      <b-table
                          bordered
                          :items="scheduleList"
                          :fields="getColumns"
                      >
                        <template v-slot:cell(index)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(interestAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.interestAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(paymentDate)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.paymentDate }}</span>
                          <span v-else>{{ getLoanDate }}</span>
                        </template>
                        <template v-slot:cell(mainAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.mainAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(totalAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.totalAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(balance)="data">
                          <span v-if="(data.index < scheduleList.length-1)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ scheduleList[data.index+1].balance | currecryZero }}</span>
                          <span v-else>0</span>
                        </template>
                        <template v-slot:cell(fee)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.fee | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(savingMonthlyAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.savingMonthlyAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(unpaidInterestAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.unpaidInterestAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                      </b-table>

                    </div>
                  </li>
                  <li>
                    <p>
                      “Нөмөр Кредит Лизинг” ХХК-ийн Худалдаа хөгжлийн банк дахь
                      460137978 тоот дансанд эргэн төлөлтийг төлнө
                    </p>
                  </li>
                </ol>
                <div class="one-page">
                  <div class="h4 text-center">
                    ТҮРЭЭСИЙН ЭРГЭН ТӨЛӨЛТИЙН ХУВААРИЙГ БАТАЛГААЖУУЛСАН:
                  </div>
                  <div class="ml-4 ">
                    <b-row class="mt-5">
                      <b-col>
                        <div class="h4">ТҮРЭЭСЛҮҮЛЭГЧИЙГ ТӨЛӨӨЛЖ:</div>
                      </b-col>
                      <b-col>
                        <div class="h4">ТҮРЭЭСЛЭГЧ:</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p>___________________________</p>
                        <p>
                          Нэр: Б.Одхүү<br />Албан тушаал: “Нөмөр Кредит Лизинг”
                          ХХК-ын ахлах менежер<br />Утас:
                          75111133
                        </p>
                      </b-col>
                      <b-col v-if="selectCustomer">
                        <p>___________________________</p>
                        <p>
                          Нэр: {{ selectCustomer.lastname }}
                          {{ selectCustomer.firstname }} <br />
                          РД: {{ selectCustomer.registerNumber }} <br />
                          Утас: {{ selectCustomer.phoneNumber }} <br />
                          Хаяг: {{ selectCustomer.address }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row class="mt-3">
                      <b-col>
                        <h4 class="h4">&nbsp;</h4>
                        <p class="mt-4">___________________________</p>
                        <p>
                          Нэр: {{ this.$store.getters.nicename }}<br />Албан
                          тушаал: “Нөмөр Кредит Лизинг” ХХК-ын харилцааны менежер<br />Утас:
                          {{ this.$store.getters.phone }}
                        </p>
                      </b-col>
                      <b-col v-if="coCustomer">
                        <h4 class="h4">ХАМТРАН ҮҮРЭГ ГҮЙЦЭТГЭГЧ:</h4>
                        <p>___________________________</p>
                        <p>
                          Нэр: {{ coCustomer.lastname }}
                          {{ coCustomer.firstname }} <br />
                          РД: {{ coCustomer.registerNumber }} <br />
                          Утас: {{ coCustomer.phoneNumber }} <br />
                          Хаяг: {{ coCustomer.address }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td>
              <div class="page">
                <div class="clearfix">
                    <span class="pull-right contract_number"
                    >ЗГ-{{ contract_number }} тоот гэрээний Хавсралт №1</span
                    >
                </div>
                <div class="h4 text-center mt-3 mb-5">
                  ЗЭЭЛИЙН ЭРГЭН ТӨЛӨЛТИЙН ХУВААРЬ
                </div>
                <ol>
                  <li>
                    <p v-if="selectCustomer">
                      Зээлдэгч {{ selectCustomer.lastname }} овогтэй
                      {{ selectCustomer.firstname }} /{{
                        selectCustomer.registerNumber
                      }}/ нь “Нөмөр кредит ББСБ” ХХК-аас авсан
                      {{ amount | number }} төгрөгийн зээл, түүний хүүг доорх
                      хуваарийн дагуу төлж барагдуулна.Үүнд:
                    </p>
                    <div class="text-center">
                      <b-table
                          bordered
                          :items="scheduleList"
                          :fields="getColumns"
                      >
                        <template v-slot:cell(index)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(interestAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.interestAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(paymentDate)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.paymentDate }}</span>
                          <span v-else>{{ getLoanDate }}</span>
                        </template>
                        <template v-slot:cell(mainAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.mainAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(totalAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.totalAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(balance)="data">
                          <span v-if="(data.index < scheduleList.length-1)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ scheduleList[data.index+1].balance | currecryZero }}</span>
                          <span v-else>0</span>
                        </template>
                        <template v-slot:cell(fee)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.fee | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(savingMonthlyAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.savingMonthlyAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                        <template v-slot:cell(unpaidInterestAmount)="data">
                          <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.unpaidInterestAmount | currecryZero }}</span>
                          <span v-else></span>
                        </template>
                      </b-table>

                    </div>
                  </li>
                  <li>
                    <p>
                      “Нөмөр Кредит ББСБ” ХХК-ийн Хаан банк дахь 5434077338,
                      5034992840 тоот данс, Худалдаа хөгжлийн банк дахь
                      460029584 тоот данс, Голомт банк дахь 8125102523 дансуудаас
                      сонгон эргэн төлөлтийг төлнө
                    </p>
                  </li>
                </ol>
                <div class="one-page">
                  <div class="h4 text-center">
                    ЗЭЭЛИЙН ЭРГЭН ТӨЛӨЛТИЙН ХУВААРИЙГ БАТАЛГААЖУУЛСАН:
                  </div>
                  <div class="ml-4 ">
                    <b-row class="mt-5">
                      <b-col>
                        <div class="h4">ЗЭЭЛДҮҮЛЭГЧИЙГ ТӨЛӨӨЛЖ:</div>
                      </b-col>
                      <b-col>
                        <div class="h4">ЗЭЭЛДЭГЧ:</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p>___________________________</p>
                        <p>
                          Нэр: Б.Одхүү<br />Албан тушаал: “Нөмөр Кредит”
                          ББСБ-ын зээлийн ахлах эдийн засагч<br />Утас:
                          75111133
                        </p>
                      </b-col>
                      <b-col v-if="selectCustomer">
                        <p>___________________________</p>
                        <p>
                          Нэр: {{ selectCustomer.lastname }}
                          {{ selectCustomer.firstname }} <br />
                          РД: {{ selectCustomer.registerNumber }} <br />
                          Утас: {{ selectCustomer.phoneNumber }} <br />
                          Хаяг: {{ selectCustomer.address }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row class="mt-3">
                      <b-col>
                        <h4 class="h4">&nbsp;</h4>
                        <p class="mt-4">___________________________</p>
                        <p>
                          Нэр: {{ this.$store.getters.nicename }}<br />Албан
                          тушаал: “Нөмөр Кредит” ББСБ-ын зээлийн ажилтан<br />Утас:
                          {{ this.$store.getters.phone }}
                        </p>
                      </b-col>
                      <b-col v-if="coCustomer">
                        <h4 class="h4">ХАМТРАН ЗЭЭЛДЭГЧ:</h4>
                        <p>___________________________</p>
                        <p>
                          Нэр: {{ coCustomer.lastname }}
                          {{ coCustomer.firstname }} <br />
                          РД: {{ coCustomer.registerNumber }} <br />
                          Утас: {{ coCustomer.phoneNumber }} <br />
                          Хаяг: {{ coCustomer.address }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td>
              <!--place holder for the fixed-position footer-->
              <div class="page-footer-space"></div>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="print()"
          >
            Хэвлэх
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  data: function() {
    return {
      isLoading: false,
      scheduleList: [],
      getLoanDate: '',
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Төлбөр хийх огноо",
          key: "paymentDate",
          class: "text-center",
        },
        {
          label: "Үндсэн зээлээс төлөх дүн",
          key: "mainAmount",
          class: "text-right",
        },
        {
          label: "Хүүгийн төлбөрийн дүн",
          key: "interestAmount",
          class: "text-right",
        },
        {
          label: "Нийт төлбөрийн дүн",
          key: "totalAmount",
          class: "text-right",
        },
        {
          label: "Үндсэн зээлийн үлдэгдэл",
          key: "balance",
          class: "text-right",
        },
        {
          label: "Шимтгэл",
          key: "fee",
          class: "text-right",
        },
      ],
    };
  },
  props: {
    selectCustomer: { type: [Array, Object], default: () => [] },
    coCustomer: { type: [Array, Object], default: () => [] },
    contract_number: { type: String, default: "" },
    repaymentDay: { type: Number, default: null },
    amount: { type: Number, default: 0 },
    fee: { type: Number, default: 0 },
    interest: { type: Number, default: 0 },
    term: { type: Number, default: 1 },
    date: { type: String, default: "" },
    noInterestTerm: { type: Number, default: null },
    savingMonthlyAmount: { type: Number, default: 0 },
    unpaidInterest: { type: Boolean, default: false },
    unpaidInterestAmount: { type: Number, default: 0 },
    unpaidInterestMonth: { type: Number, default: 1 },
    loanGroup: { type: String, default: "" }
  },
  computed: {
    getColumns() {
      let result = this.$data.columns;
      if (this.savingMonthlyAmount > 0) {
        result.splice(4, 0, {
          label: "Хадгаламжийн дүн",
          key: "savingMonthlyAmount",
          class: "text-right",
        });
      }

      if(this.unpaidInterest) {
        result.splice(4, 0, {
          label: "Төлөгдөөгүй хүү",
          key: "unpaidInterestAmount",
          class: "text-right",
        })
      }

      return result;
    },
  },
  methods: {
    showRepaymentSchedules: function() {
      if (this.contract_number && this.contract_number != "") {
        if (this.repaymentDay) {
          if (this.amount > 0) {
            this.generateRepaymentSchedule();
          } else {
            this.showToast("Анхаар", "Зээлийн дүн оруулаагүй байна", "warning");
          }
        } else {
          this.showToast("Анхаар", "Төлөх өдөр сонгоогүй байна", "warning");
        }
      } else
        [
          this.showToast(
              "Анхаар",
              "Гэрээний дугаар оруулаагүй байна",
              "warning"
          ),
        ];
    },
    generateRepaymentSchedule: function() {
      this.$data.isLoading = true;
      var fee = (this.amount * this.fee) / 100;
      var monthPayment = 0;
      var monthlyPayment = 0;
      var k = 0;
      var dayInterestPercent = (this.interest * 12) / 365 / 100;
      var daysInMonth = parseInt(moment(this.date).format("DD"));
      var loanDate = moment(moment(this.date).format("YYYY-MM-DD"));
      this.$data.getLoanDate = moment(this.date).format("YYYY-MM-DD")
      var lastDay = moment(loanDate.format("YYYY-MM-DD"));
      if (daysInMonth > this.repaymentDay) {
        lastDay = moment(
            lastDay.add(1, "month").format("YYYY-MM-" + this.repaymentDay)
        );
        var days = lastDay.diff(loanDate, "days");
        k = days >= 15 ? 1 : 2;
      } else {
        lastDay = moment(lastDay.format("YYYY-MM-" + this.repaymentDay));
        var days = lastDay.diff(loanDate, "days");
        k = days <= 15 ? 1 : 0;
      }

      monthPayment = this.getMonthlyPayment(
          this.amount,
          this.interest,
          this.term,
          this.date,
          this.repaymentDay,
          k
      );
      var balance = this.amount;
      var firstDate = moment(moment(this.date).format("YYYY-MM-DD"));
      var days = 0;
      var noInterstMonth = this.noInterestTerm ? this.noInterestTerm : 0;
      var totalNoInterestAmount = 0;

      let scheduleTotal = {
        paymentDate: 'Нийт',
        interestAmount: 0,
        mainAmount: 0,
        fee: 0,
        totalAmount: 0,
        savingMonthlyAmount: 0
      }

      this.$data.scheduleList = [{}];
      for (var i = 0; i < this.term; i++) {
        var payDay = moment(
            moment(this.date)
                .add(i + k, "month")
                .format("YYYY-MM-" + this.repaymentDay)
        );
        days = payDay.diff(firstDate, "days");

        var interestAmount = balance * dayInterestPercent * days;
        var mainAmount = monthPayment - interestAmount;
        var totalAmount = monthPayment;
        if (noInterstMonth > 0) {
          totalNoInterestAmount += interestAmount;
          interestAmount = 0;
          totalNoInterestAmount = mainAmount;
        }
        var schedule = {
          balance: balance,
          paymentDate: payDay.format("YYYY-MM-DD"),
          interestDay: days,
          interestAmount: interestAmount,
          mainAmount: mainAmount,
          fee: 0,
          totalAmount: totalAmount,
          savingMonthlyAmount: this.savingMonthlyAmount,
        };

        if(this.unpaidInterest && i<this.unpaidInterestMonth) {
          schedule.unpaidInterestAmount = this.unpaidInterestAmount/this.unpaidInterestMonth
          totalAmount += schedule.unpaidInterestAmount
        }

        if (fee > 0 && i + 1 == this.term) {
          schedule.fee = fee;
          totalAmount += fee;
        }
        totalAmount = totalAmount + this.savingMonthlyAmount;
        schedule.totalAmount = totalAmount;
        balance -= mainAmount;



        firstDate = payDay;
        noInterstMonth--;

        this.$data.scheduleList.push(schedule);

        scheduleTotal.interestAmount += schedule.interestAmount
        scheduleTotal.mainAmount += schedule.mainAmount
        scheduleTotal.totalAmount += schedule.totalAmount
        scheduleTotal.fee += schedule.fee
        scheduleTotal.savingMonthlyAmount += schedule.savingMonthlyAmount
      }
      this.$data.scheduleList.push(scheduleTotal);
      this.$data.isLoading = false;
      this.$refs["my-modal"].show();
    },
    getMonthlyPayment: function(
        loanAmount,
        interest,
        term,
        date,
        repaymentDay,
        k
    ) {
      var monthlyPayment = 0;
      var rate = 1;
      var totalRate = 0;
      var yearInterest = (interest * 12) / 100;
      var days = 0;
      var firstDate = moment(moment(date).format("YYYY-MM-DD"));
      for (var i = 0; i < term; i++) {
        var payDay = moment(
            moment(date)
                .add(i + k, "month")
                .format("YYYY-MM-" + repaymentDay)
        );
        days = payDay.diff(firstDate, "days");
        rate = this.decimalAdjust(
            "round",
            rate / ((days * yearInterest) / 365 + 1),
            -10
        );
        console.log(days, i, rate, yearInterest, k)
        console.log(firstDate, payDay)
        totalRate += rate;

        firstDate = payDay;
      }

      monthlyPayment = this.decimalAdjust("round", loanAmount / totalRate, -10);
      return monthlyPayment;
    },
    decimalAdjust: function(type, value, exp) {
      // Если степень не определена, либо равна нулю...
      if (typeof exp === "undefined" || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // Если значение не является числом, либо степень не является целым числом...
      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
        return NaN;
      }
      // Сдвиг разрядов
      value = value.toString().split("e");
      value = Math[type](
          +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp))
      );
      // Обратный сдвиг
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
    },
    print() {
      this.$htmlToPaper("printContainer");
    },
  },
};
</script>
